<template>
  <VAutocompleteWithValidation
    v-model="innerValue"
    :async-loading="loading"
    label="Category"
    :placeholder="placeholder"
    :items="foundCategories"
    :search-input.sync="search"
    item-value="id"
    item-text="name"
    v-bind="$attrs"
    no-filter
    custom-append-outer
    custom-item
    custom-no-data
  >
    <template #no-data>
      <v-list-item>
        <v-list-item-title>No categories found</v-list-item-title>
      </v-list-item>
      <v-list-item @click="createNewCategory">
        <v-list-item-title>Create a new category</v-list-item-title>
        <v-list-item-action class="ma-0">
          <v-btn small icon>
            <v-icon color="grey lighten-1">mdi-plus</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>

    <template #item="{ item }">
      <v-list-item-content v-if="item !== null">
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item v-else disabled>
        <!-- TODO: use data attr item-disabled="a => a === null?" -->
        <v-list-item-title>Type to search a category</v-list-item-title>
      </v-list-item>
    </template>

    <template v-if="!_.isUndefined(category) && _.isUndefined(innerValue)" #append-outer>
      <v-icon @click="reset" v-text="'mdi-history'" />
    </template>
  </VAutocompleteWithValidation>
</template>

<script>
import { computed, defineComponent, onBeforeUnmount, ref, watch } from '@vue/composition-api'
import { isUndefined } from 'lodash'

import VAutocompleteWithValidation from '@/components/inputs/VAutocompleteWithValidation'

export default defineComponent({
  name: 'SearchSelectCategory',
  components: {
    VAutocompleteWithValidation,
  },
  props: {
    items: {
      default: _ => [],
      type: Array,
    },
    category: {
      default: _ => {},
      type: Object,
    },
    // must be included in props
    value: {
      type: null,
      default: null,
    },
  },
  setup(props, { root: { $store }, emit }) {
    const delayTimer = ref(null)
    const loading = ref(false)
    const search = ref(null)
    const innerValue = ref(null)

    const foundCategories = computed(_ => $store.state.category.foundCategories)
    const searchCategory = search => $store.dispatch('category/searchCategory', search)
    const clearFoundCategories = _ => $store.dispatch('category/clearFoundCategories')


    const placeholder = computed(_ => {
      let placeholder = 'Search Category'
      if (!isUndefined(props.category)) {
        placeholder = props.category.name
      }
      return placeholder
    })

    if (props.value) {
      innerValue.value = props.value
    }

    watch(search, val => val && searchForCategory(val))
    // Handles internal model changes.
    watch(innerValue, val => emit('input', val))
    // Handles external model changes.
    watch(_ => props.value, val => innerValue.value = val)

    const reset = _ => {
      if (!isUndefined(props.category) && isUndefined(innerValue.value)) {
        clearFoundCategories()
        innerValue.value = props.category.id
      }
    }

    const searchForCategory = search => {
      if (search.length >= 3) {
        clearTimeout(delayTimer.value)
        delayTimer.value = setTimeout(() => {
          loading.value = true
          searchCategory(search)
            .then(response => {
              // if (response && response.errors) {
              //   this.errors = response.errors
              // } else {
              //   this.errors = null
              // }
              // this.loading = false
            })
            .catch(error => {
              // this.errors = error
              // this.loading = false
            })
            .finally(() => (loading.value = false))
        }, 1000)
      }
    }

    const createNewCategory = _ => {
      // console.log('create new category')
    }

    onBeforeUnmount(_ => {
      clearFoundCategories()
    })

    return {
      innerValue,
      loading,
      placeholder,
      foundCategories,
      search,
      reset,
      createNewCategory,
    }
  },
})
</script>
